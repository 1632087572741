
import cmap from "configs/CountryCodeShortMapping.json";
import { Buffer as buffer } from 'buffer';
import { notification } from "antd";
import { ApiErrorResponse } from 'models/responses';

const findCountryCode = (dialCode: string) => {
  const data = cmap.find((x) => dialCode.includes(x.dial_code) === true);
  return data?.code;
};
const findCountryDialCode = (code: string) => {
  const data = cmap.find((x) => code.toUpperCase() === x.code);
  return data?.dial_code;
};
const findCountry = (code: string) => {
  const data = cmap.find((x) => code.toUpperCase() === x.code);
  return data?.name ?? code;
};
const decode = (str: string): string => buffer.from(str, 'base64').toString('binary');

const showErrorNotification = (message: string, description?: string) => {
  const notificationParam = {
    message,
    description
  };
  notification.error(notificationParam);
};

const showSuccessNotification = (message: string, description?: string) => {
  const notificationParam = {
    message,
    description
  };
  notification.success(notificationParam);
};
const showApiErrorNotification = (response?: ApiErrorResponse) => {
  if (response) {
    const notificationParam = {
      message: response.message,
      description: response.description
    };
    notification.error(notificationParam);
  }
};
const handleApiError = (error: unknown) => {
  const errorResponse = error as ApiErrorResponse;
  showApiErrorNotification(errorResponse);
};
export {
  findCountryCode,
  findCountryDialCode,
  findCountry,
  decode,
  showErrorNotification,
  showSuccessNotification,
  showApiErrorNotification,
  handleApiError
};
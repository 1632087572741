import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Tag } from "antd";
import navigationConfig from "configs/NavigationConfig";
import { useSelector, useDispatch } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils';
import { onMobileNavToggle } from "redux/actions/Theme";
import { RootState } from 'App';
import type { MenuProps } from 'antd';
import IntlMessage from "../utils/IntlMessage";
import Icon from "../utils/Icon";


const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn?: boolean, localeKey?: string) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey?.toString();

const setLocaleWithTag = (isLocaleOn?: boolean, localeKey?: string, localValue?: string) =>
  isLocaleOn ? <div><span style={{marginRight:"60px"}}>{localValue}</span><Tag color="lime">New</Tag> </div> : localeKey?.toString();

// const setDefaultOpen = (key: string) => {
//   const keyList = [];
//   let keyString = "";
//   if (key) {
//     const arr = key.split("-");
//     // eslint-disable-next-line no-plusplus
//     for (let index = 0; index < arr.length; index++) {
//       const elm = arr[index];
//       // eslint-disable-next-line no-unused-expressions
//       index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
//       keyList.push(keyString);
//     }
//   }
//   return keyList;
// };

const SideNavContent = (props: { routeInfo?: any, hideGroupTitle?: boolean, localization?: boolean }) => {
  const { routeInfo, hideGroupTitle, localization } = props;
  const dispatch = useDispatch();
  const routeKey=routeInfo?.title?.split('.')[1];
  const [openKeys, setOpenKeys] = useState([`/apps-${routeKey==="dashboard"?"sms":routeKey}`]);

  const { sideNavTheme } = useSelector((state: RootState) => state.theme);
  const { permissionMaps } = useSelector((state: RootState) => state.auth);
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      dispatch(onMobileNavToggle(false));
    }
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && ['/dashboard', '/apps-contacts', '/apps-billing', '/apps-sms', '/apps-verify', '/apps-whatsapp', '/apps-whatsapp-meta', '/apps-numlookup', '/apps-viber'].indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const checkPermission = (path: string, isParent: boolean) => {
    if (permissionMaps.findIndex(pm => pm.path === path) >= 0) {
      return true;
    }
    return false;
  };
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0, fontWeight:400 }}
      defaultSelectedKeys={[routeInfo?.key]}
      selectedKeys={[routeInfo?.key]}
      // defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                checkPermission(subMenuFirst.key, true) && <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={(subMenuFirst.title==="sidenav.whatsapp" || subMenuFirst.title==="sidenav.whatsapp-meta")?setLocaleWithTag(localization, subMenuFirst.title, "Whatsapp"):setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    checkPermission(subMenuSecond.key, false) && <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                checkPermission(subMenuFirst.key, true) && <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          checkPermission(menu.key, true) && <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};
SideNavContent.defaultProps = {
  hideGroupTitle: false,
  routeInfo: undefined,
  localization: false
};
const TopNavContent = (props: { localization?: boolean }) => {
  const { localization } = props;

  const { topNavColor } = useSelector((state: RootState) => state.theme);
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};
TopNavContent.defaultProps = {
  localization: false
};
const MenuContent = (props: { type: any, routeInfo?: any, hideGroupTitle?: boolean, localization?: boolean }) => {
  const { type, routeInfo, hideGroupTitle, localization } = props;

  return type === NAV_TYPE_SIDE ? (
    <SideNavContent routeInfo={routeInfo} hideGroupTitle={hideGroupTitle} localization={localization} />
  ) : (
    <TopNavContent localization={localization} />
  );
};
MenuContent.defaultProps = {
  hideGroupTitle: false,
  routeInfo: undefined,
  localization: false
};
export default MenuContent;

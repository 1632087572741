import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import {
  // DollarOutlined,
  SettingOutlined,
  // QuestionCircleOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/utils/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'redux/actions/Auth';
import { RootState } from "App";
import { AuthState } from "models";

export const NavProfile = () => {
  const dispatch = useDispatch();
  const {
    user,
    // acl
  } = useSelector((state: RootState): AuthState => state.auth);

  const getName = () => `${user?.profile?.first_name ?? ''} ${user?.profile?.last_name ?? ''}`;
  const getFirstLetterName = () => `${(user?.profile?.first_name ?? user?.email ?? '').substring(0, 1).toUpperCase()}`;
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">

          <Avatar style={{ background: '#2B9DFF' }}>
            <span>{getFirstLetterName()}</span>
          </Avatar>
          <div className="pl-3">
            <h4 className="mb-2">{getName()}</h4>
            <span className="text-muted">{user?.email ?? ''}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* <Menu.Item key={0}> */}
          {/*  <a href='https://d7networks.com/contact/' target='_blank' rel="noreferrer"> */}
          {/*    <Icon type={QuestionCircleOutlined} /> */}
          {/*    <span className="font-weight-normal">Support</span> */}
          {/*  </a> */}
          {/* </Menu.Item> */}
          {/* {((acl && acl.BILLING_PAYMENT.length>0) || (acl && acl.ALL_MODULES&& acl.ALL_MODULES?.length>0)) && <Menu.Item key={1}> */}
          {/*  <a href='/billing/payment' target='_self'> */}
          {/*    <Icon type={DollarOutlined} /> */}
          {/*    <span className="font-weight-normal">Billing</span> */}
          {/*  </a> */}
          {/* </Menu.Item>} */}
          <Menu.Item key={2}>
            <a href='/users/preference/account' target='_self'>
              <Icon type={SettingOutlined} />
              <span className="font-weight-normal">User Preference</span>
            </a>
          </Menu.Item>
          <Menu.Item key={3} onClick={() => dispatch(signOut())}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );


  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">

          <Avatar style={{ background: '#2B9DFF' }}>
            <span>{getFirstLetterName()}</span>
          </Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;

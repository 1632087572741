import { getDefaultPermisionMaps } from 'configs/PermissionConfig';
import { AuthState, PermissionMap, User } from "models";
import { AuthListResponse } from 'models/responses';
import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  USER_ACL_LIST,
  FETCH_ACL_SUCCESS,
  NAVIGATE_TO_HOME,
  SET_USER,
  SET_USER_ACL,
  SET_USER_WALLET,
  SET_USER_TEST_CREDIT,
  SET_PERMISSION_MAPS
} from '../constants/Auth';


const initState: AuthState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  permissionMaps: getDefaultPermisionMaps(),
  token: localStorage.getItem(AUTH_TOKEN),
};

// eslint-disable-next-line default-param-last
const auth = (state: AuthState = initState, action: {
  type: string;
  token?: string; message?: any;
  permissionMaps?: PermissionMap[],
  user?: User, acl?: AuthListResponse,
  redirect?: boolean,
  wallet?: number,
  testCredit?: number
}): AuthState => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        token: action.token!
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: '/',
        loading: false
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token!
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_USER: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        user: action.user,
        loading: false
      };
    }
    case USER_ACL_LIST: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_ACL_SUCCESS: {
      return {
        ...state,
        acl: action.acl,
        loading: false
      };
    }
    case NAVIGATE_TO_HOME: {
      return {
        ...state,
        redirect: '/dashboard',
        loading: false
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: action.user,
      };
    } case SET_USER_ACL: {
      return {
        ...state,
        acl: action.acl,
      };
    } case SET_USER_WALLET: {
      return {
        ...state,
        wallet: action.wallet,
      };
    } case SET_USER_TEST_CREDIT: {
      return {
        ...state,
        testCredit: action.testCredit,
      };
    }
    case SET_PERMISSION_MAPS: {
      return {
        ...state,
        permissionMaps: action.permissionMaps ?? getDefaultPermisionMaps(),
      };
    }

    default:
      return state;
  }
};

export default auth;

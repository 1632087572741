import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from "antd";

import { RootState } from 'App';

import { ValidateEmailContent, ValidateEmailForm } from 'components/signUp/ValidateEmailStep';
import { RegistrationContent, RegistrationForm } from 'components/signUp/RegistrationStep';
import { BasicInfoContent, BasicInfoForm } from 'components/signUp/BasicInfo';
import WelcomeSideBanner from 'components/layout/WelcomeSideBanner';
import {Helmet} from "react-helmet";

const SignUp = () => {
	const theme = useSelector((state: RootState) => state.theme.currentTheme);
	const [currentStep, setCurrentStep] = useState(0);
	const [validatedEmail, setValidatedEmail] = useState('');
	const totalSteps = 2;
	const onClickNext = (nextStep: number, email: string) => {
		setValidatedEmail(email);
		setCurrentStep(nextStep);
	};

	const registrationSteps = [{
		right: <ValidateEmailContent />,
		left: <ValidateEmailForm onclickNext={onClickNext} />
	},
	{
		right: <RegistrationContent />,
		left: <RegistrationForm validatedEmail={validatedEmail} />
	},
	{
		right: <BasicInfoContent />,
		left: <BasicInfoForm validatedEmail={validatedEmail} />
	}];


	return (
		<div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
      <Helmet>
        <title>Sign Up Today-An Online SMS Portal</title>
      </Helmet>
			<Row justify="center" className="align-items-stretch h-100">
				<Col xs={20} sm={20} md={24} lg={16}>
          <div className="text-left">
            <img src="/img/logo-white.png" alt="logo" />
          </div>
					<div className="container d-flex flex-column justify-content-center h-100">
						<Row justify="center">
							<Col xs={24} sm={24} md={20} lg={12} xl={8}>
								{/* <div className="text-right mt-2"><span >Step {currentStep === 0 ? 1 : 2}/{totalSteps}</span></div> */}
								<Row justify="center" gutter={24}>
									{/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
											{registrationSteps[currentStep].right}
										</Col> */}
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										{registrationSteps[currentStep].right}
										<div className="text-right mt-2"><span >Step {currentStep === 0 ? 1 : 2}/{totalSteps}</span></div>

										<div className='pt-2 pb-4'>
											{registrationSteps[currentStep].left}
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Col>

				<Col xs={0} sm={0} md={0} lg={8}>
					<WelcomeSideBanner />
				</Col>
			</Row>
		</div>
	);
};

export default SignUp;

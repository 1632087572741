/* eslint-disable no-param-reassign */
import axios from 'axios';
import {API_BASE_URL} from 'configs/AppConfig';
import {AUTH_TOKEN, REFRESH_TOKEN, USER_ACL_LIST} from 'redux/constants/Auth';
import {notification} from 'antd';
import {ApiErrorResponse, ErrorResponse, ValidationErrorResponse} from 'models/responses';
import {refreshTokenService} from 'services/auth/LoginService';
import store from 'redux/store';

const ApiClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Config

const TOKEN_PAYLOAD_KEY = 'Authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
ApiClient.interceptors.request.use((config) => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN);
  if (!config.headers) {
    config.headers = {};
  }
  if (jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
    config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
  }
  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
    window.location.reload();
  }
  return config;
}, error => {
  // Do something with request error here
  notification.error({
    message: 'Error'
  });
  Promise.reject(error);
});
// let retryRequest = false;
// API respone interceptormethod = "get"
ApiClient.interceptors.response.use((response) => response.data, (error) => {
  let validationError: ValidationErrorResponse | undefined;
  let commonError: ErrorResponse | undefined;
  const handledError = false;
  let message = 'Internal Server Error';
  let description = '';
  commonError = error.response.data;

  if (error?.response?.data) {
    message = error.response.data.detail?.message ?? error.response.data.detail;
  }
  if (error.response.status === 400) {
    message = message ?? 'Bad Request';
  }
  if (error.response.status === 404) {
    message = message ?? 'Not Found';
  }
  if (error.response.status === 422) {
    validationError = error.response.data;
    commonError = undefined;
    message = 'Validation Error';
    description = validationError?.detail?.map(x => x.msg)?.join("/n") ?? '';
  }
  if (error.response.status === 402) {
    validationError = error.response.data;
    commonError = undefined;
    message = validationError?.detail?.map(x => x.message)?.join("/n") ?? '';
  }
  if (error.response.status === 500) {
    message = message ?? 'Internal Server Error';
  }
  if (error.response.status === 508 || error.response.status === 504) {
    message = 'Time Out';
  }


  const response: ApiErrorResponse = {
    status: error.response.status,
    code: commonError?.detail?.code,
    error: commonError,
    message,
    handledError,
    validationError,
    description
  };
  const originalRequest = error.config;
  if (error.response.status === 401) {
    const errorDetail = commonError?.detail;
    if (errorDetail?.code === "REFRESH_TOKEN_EXPIRED") {
      localStorage.removeItem(AUTH_TOKEN);
      window.location.reload();
      return Promise.reject(response);
    }
    if (errorDetail?.code === "ACCESS_TOKEN_EXPIRED") {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      if (refreshToken) {
        return refreshTokenService(refreshToken).then((resp) => {
          if (resp.access_token) {
            originalRequest.headers.Authorization = `Bearer ${resp.access_token}`;
            const request = ApiClient(originalRequest);
            ApiClient.defaults.headers.common.Authorization = `Bearer ${resp.access_token}`;
            localStorage.setItem(AUTH_TOKEN, resp.access_token);
            return request;
          }
          localStorage.removeItem(AUTH_TOKEN);
          window.location.reload();
          return Promise.reject(response);
        }).catch(err => {
          localStorage.removeItem(AUTH_TOKEN);
          window.location.reload();
          return Promise.reject(response);
        });
      }
      localStorage.removeItem(AUTH_TOKEN);
      window.location.reload();
    }
    else{
      localStorage.removeItem(AUTH_TOKEN);
      window.location.reload();
      return Promise.reject(response);
    }

  } else if (error.response.status === 403) {
    store.dispatch({type: USER_ACL_LIST, redirect: false});
    if (!`${originalRequest.url}`.includes('payment/user-wallet')) {
      window.location.href = '/error/403';
    }
  }
  return Promise.reject(response);
});

const AxiosService = {
  getHeaders: (isPublic: boolean = false, headers: any = {}) => {
    const newHeaders = {...headers};
    if (isPublic) {
      newHeaders['public-request'] = 'true';
    }
    return newHeaders;
  },
  post: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => ApiClient.post<D, R>(
    url,
    data,
    {headers: AxiosService.getHeaders(isPublic, headers)}
  ),
  postWithQueryParams: <R = any, P = any, D = any>(url: string, queryParams?: P, data?: D, isPublic: boolean = false, headers: any = {}) => ApiClient.post<D, R>(
    url,
    data,
    {params: queryParams, headers: AxiosService.getHeaders(isPublic, headers)}
  ),
  put: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => ApiClient.put<D, R>(
    url,
    data,
    {headers: AxiosService.getHeaders(isPublic, headers)}
  ),
  get: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => ApiClient.get<D, R>(
    url,
    {
      params: data,
      headers: AxiosService.getHeaders(isPublic, headers)
    }
  ),
  delete: <R = any, D = any, >(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => ApiClient.delete<D, R>(
    url,
    {
      params: data,
      headers: AxiosService.getHeaders(isPublic, headers)
    }
  ),
};

export default AxiosService;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Layout} from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils';
import { RootState } from 'App';
import Logo from './Logo';
import NavSearch from './NavSearch';
import NavProfile from './NavProfile';
import WalletCredit from "./WalletCredit";


const { Header } = Layout;

export const HeaderNav = (props: { isMobile: boolean }) => {
  const { isMobile } = props;
  const { navCollapsed, mobileNav, navType, headerNavColor, currentTheme } = useSelector((state: RootState) => state.theme);
  const { wallet, user, testCredit } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const [searchActive, setSearchActive] = useState(false);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      dispatch(toggleCollapsedNav(!navCollapsed));
    } else {
      dispatch(onMobileNavToggle(!mobileNav));
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff');
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;

  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }

    // if (window && document) {
    //   const existing = document.getElementById('hs-script-loader');
    //   if (!existing) {
    //     const script = document.createElement('script');
    //     script.id = 'hs-script-loader';
    //     script.async = true;
    //     script.defer = true;
    //     const head = document.getElementsByTagName('head')[0];
    //     script.src = '//js-eu1.hs-scripts.com/25428574.js';
    //     head.appendChild(script);
    //   }
    // }
  });

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {
                isNavTop && !isMobile ?
                  null
                  :
                  <li className="ant-menu-item ant-menu-item-only-child" onClick={() => { onToggle(); }}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                  </li>
              }
            </ul>
          </div>
          {/* <Alert */}
          {/*  style={{ width: "1200px", backgroundColor:"#FFFFFF", textAlign:"center", overflow:"auto"}} */}
          {/*  banner */}
          {/*  showIcon={false} */}
          {/*  message={<div style={{color:"#467c64"}}>★★ Exciting News! 🚀 Introducing WhatsApp API  <span style={{color:"#25D366"}} ><WhatsAppOutlined/></span> - Connect with your audience on a whole new level. Try it today! ★★</div>} */}
          {/* /> */}
          <div className="nav-right">
            <div className="d-flex pr-4 pl-4 flex-column align-items-center" style={{ position: "relative", bottom: "7px" }}>
              <WalletCredit user={user} wallet={wallet} testCredit={testCredit}/>

            </div>
            <Divider type="vertical" style={{ height: "40px", alignSelf: "center" }} />


            <NavProfile />
            {/* <NavPanel direction={direction} /> */}
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header >
  );
};



export default HeaderNav;

import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const GettingStarted = lazy(() => import("./GettingStarted"));
const Settings = lazy(() => import("./Settings"));
const Campaign = lazy(() => import("./Campaign"));
const ManageCampaign = lazy(() => import("./ManageCampaign"));
const CampaignReport = lazy(() => import("./CampaignReport"));
const ApiReport = lazy(() => import("./ApiReport"));
const ApiLogs = lazy(() => import("./ApiLogs"));
const ScheduledMsgReport = lazy(() => import("./ScheduledMsgReport"));
const Pricing = lazy (() => import("./Pricing"));
const Template = lazy(()=>import("./Template"));

const SMSPages = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/sms/getting-started" replace />} />
    <Route path='getting-started' element={<GettingStarted />} />
    <Route path='settings' element={<Settings />} />
    <Route path='campaign' element={<Campaign />}>
      <Route path=':id' element={<Campaign />} />
    </Route>
    <Route path='manage-campaign' element={<ManageCampaign />} />
    <Route path='campaign-report' element={<CampaignReport />} />
    <Route path='api-report' element={<ApiReport />} />
    <Route path='api-logs' element={<ApiLogs />} />
    <Route path='scheduled-messages' element={<ScheduledMsgReport />} />
    <Route path='pricing' element={<Pricing />} />
    <Route path='template' element={<Template />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default SMSPages;

import React, { useEffect, useState } from 'react';
import { RootState } from 'App';
import { useSelector } from 'react-redux';
import { Col, notification, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyForgotPasswordService } from 'services/auth/UserService';
import ResetForgotPasswordForm from 'components/forgotPassword/ForgotPasswordVerify';
import ForgotPasswordForm from 'components/forgotPassword/ForgotPasswordForm';
import WelcomeSideBanner from 'components/layout/WelcomeSideBanner';

const ForgotPasswordPage = () => {
  const { token } = useParams();
  const [isVerify, setIsVerify] = useState(false);
  const theme = useSelector((state: RootState) => state.theme.currentTheme);
  const navigate = useNavigate();

  const onSuccess = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };
  const onError = (message: string) => {

    const notificationParam = {
      message,
      description: ''
    };
    notification.error(notificationParam);
  };

  const resetPassword = async (password: string) => {
    try {
      if (token) {
        const response = await verifyForgotPasswordService({
          token,
          password
        });
        if (response.detail) {
          onSuccess('Password reset success');
          navigate('/signin');
        } else {
          onError('Password reset failed');
        }
      } else {
        onError('Invalid token');
      }
    } catch (error) {
      onError('Password reset failed');
    }
  };

  useEffect(() => {
    if (token) {
      setIsVerify(true);
    }
  }, [token]);

  return (
    <div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="text-left">
            <img src="/img/logo-white.png" alt="logo" />
          </div>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                <h1>{isVerify ? 'Enter New Password' : 'Enter registered email'}</h1>
                <div className="mt-4">
                  {
                    isVerify ?
                      <ResetForgotPasswordForm onSubmit={resetPassword} /> :
                      <ForgotPasswordForm />
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={0} sm={0} md={0} lg={8}>
          <WelcomeSideBanner />
        </Col>
      </Row>
    </div>);
}
  ;

export default ForgotPasswordPage;

import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Contacts = lazy(() => import("./Contacts"));
const Groups = lazy(() => import("./Groups"));

const ContactPages = () => (
  <Routes>
    <Route path="/" element={<Contacts />} />
    <Route path='groups' element={<Groups />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>

);

export default ContactPages;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SideNav from 'components/layout/SideNav';
import TopNav from 'components/layout/TopNav';
import Loading from 'components/shared/Loading';
import MobileNav from 'components/layout/MobileNav';
import HeaderNav from 'components/layout/HeaderNav';
import PageHeader from 'components/layout/PageHeader';
// import Footer from 'components/layout/Footer';
import { RootState } from 'App';

import {
  Layout,
  Grid,
} from "antd";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import navigationConfig from "configs/NavigationConfig";
import { setPermissionMaps, setUser, setUserACL, setUserWallet, setUserTestCredit } from 'redux/actions';
import { getAccessListService, getUserService } from 'services/auth/UserService';
import { getUserWalletService } from 'services/dashboard/PaymentService';
import { getPermisionMapsByACL } from 'configs/PermissionConfig';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from "react-css-theme-switcher";
import { AuthState } from "models";
// import { MonthlySMS } from 'components/dashboard';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const AppPageLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { navCollapsed, navType, direction } = useSelector((state: RootState) => state.theme);

  const {
    user, token
  } = useSelector((state: RootState): AuthState => state.auth);

  const [loading, setLoading] = useState(false);

  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    const width = navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
    return width;
  };

  const { status } = useThemeSwitcher();



  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };
  const fetchUser = async () => {
    const response = await getUserService();
    if (response) {
      dispatch(setUser(response));
      return true;
    }
    return false;
  };

  const fetchUserAccessList = async () => {
    const response = await getAccessListService();
    if (response) {
      const permissions = getPermisionMapsByACL(response);
      dispatch(setUserACL(response));
      dispatch(setPermissionMaps(permissions));
      return true;
    }
    return false;
  };
  const fetchUserWallet = async () => {
    let result = true;
    try {
      const response = await getUserWalletService();
      if (response.credit !== undefined) {
        dispatch(setUserWallet(response.credit));
        dispatch(setUserTestCredit(response.test_credit));
      }
    } catch (error) {
      result = false;
    }
    return result;

  };
  const onSuccessFetchInfos = () => {
    setLoading(false);
  };

  const loadUserInfo = async () => {
    setLoading(true);
    Promise.all([fetchUser(), fetchUserAccessList(), fetchUserWallet()]).then(responses => {
      onSuccessFetchInfos();
    }).catch((error) => {
      setLoading(false);
      navigate('/error/500');
    });
  };
  useEffect(() => {
    if (token && !user) {
      loadUserInfo();
    }
  }, []);

  return (

    <Layout>
      {(loading || status === 'loading') ? <Loading cover="page" /> : <><HeaderNav isMobile={isMobile} />
        {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo} localization /> : null}
        <Layout className="app-container">
          {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo} localization /> : null}
          <Layout className="app-layout" style={getLayoutDirectionGutter()}>
            <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
              <PageHeader display={currentRouteInfo?.displayTitle} title={currentRouteInfo?.pageTitle} showBreadCrumb={currentRouteInfo?.breadcrumb} />

              <Content>
                <Outlet context="USerApp" />
              </Content>

            </div>
            {/* <Footer /> */}
          </Layout>
        </Layout>
        {isMobile && <MobileNav localization />}</>}

    </Layout>
  );
};

export default React.memo(AppPageLayout);
